@import "~antd/dist/antd.less";

@header-height: 60px;

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  box-shadow: inset 2px 2px 5px 0 rgba(#888888, 0.5);
}

.ant-menu-root::-webkit-scrollbar-track {
  background-color: @layout-sider-background;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.min-vh-100 {
  min-height: 100vh;
}

.site-layout-background {
  height: @header-height;
  line-height: @header-height;
  background: #fff;
}

.ant-upload-picture-card-wrapper {
  height: 100% !important;
  width: auto !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.apexcharts-svg {
  background: transparent !important;
}

@primary-color: #D4A64E;@primary-1: #f5f5f5;@layout-header-background: #d9d9d9;@layout-sider-background: #002140;@menu-highlight-color: @primary-color;@menu-dark-bg: @layout-sider-background;@menu-dark-highlight-color: #14ffb3;@menu-dark-selected-item-icon-color: #14ffb3;@menu-dark-selected-item-text-color: #14ffb3;@checkbox-color: #D4A64E;@btn-primary-bg: #D4A64E;@table-header-bg: rgb(240 240 240);@select-item-selected-color: @primary-color;@disabled-color: fade(#000, 70%);