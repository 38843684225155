/* Other */
.primary-radius-container {
  padding-top: 10px;
}

.primary-wrapper {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.full-width {
  width: 100%;
}

.color-text-sub {
  color: #d4a64e;
  cursor: pointer;
}

.float-end {
  display: flex;
  justify-content: flex-end;
}

.float-start {
  display: flex;
  justify-content: flex-start;
}

.card-small {
  height: 58px;
  overflow: hidden;
}

.img-upload {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

.upload-avatar-image > .ant-upload.ant-upload-select-picture-card {
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}
.fixed-action-bar {
  height: 56px;
  position: fixed;
  bottom: 0;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  z-index: 99;
  transition: 0.2s;
  box-shadow: 4px -2px 5px 1px #cccccc66;
  background: #f0f2f5;
}
.ant-space-item:nth-child(1) {
  flex: 1;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.video-react-video {
  width: 100%;
}
.ant-list .ant-spin-container {
  width: 99%;
}
.card-library:hover {
  opacity: 0.9;
}
.product-in-order .ant-list-items {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.member-avatar {
  width: 180px;
  margin: 0 auto;
  border-radius: 50%;
}
